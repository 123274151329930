
import { defineComponent, ref, onMounted, watch, Ref, reactive, computed } from 'vue';
import { getEstoqueConcessionaria, atualizarEstoque, getDmsConcessionaria } from '@/services/Estoque';
import ModalUpdateCodFornecedor from './components/ModalUpdateCodFornecedor.vue';
import ModalUpdateCodPeca from './components/ModalUpdateCodPeca.vue';
import ModalUpdateUnidadeCaixa from './components/ModalUpdateUnidadeCaixa.vue';
import moment from 'moment'
import { ContentLoader } from 'vue-content-loader';
import { useStore } from 'vuex';
import useAlert from "@/composables/Alert";
import useEmitter from "@/composables/Emmiter";
import { gerarXLSX, exportarXLSX } from "./export/Excel"
import usePDF from '@/composables/Pdf'
import Swal from "sweetalert2/dist/sweetalert2.js";
import { salvarSugestaoPedido } from '@/services/SugestaoPedidos';
import { getSugestaoPedidosEstoqueItens } from '@/services/SugestaoPedidos';
import { Produto } from '@/models/SugestaoPedidoModel';
import { useRoute, useRouter } from 'vue-router';

export default defineComponent({
  name: "cadastro-estoque-form",
  components: {
    ModalUpdateCodFornecedor,
    ModalUpdateCodPeca,
    ModalUpdateUnidadeCaixa,
    ContentLoader,
  },
  props: {
    title: String,
    codSugestaoPedido: Number,
    codconcessionaria: Number,
    hash_pedido: String,
    nomeConcessionaria: String
  },
  setup(props) {

    const store = useStore()
    const concessionariaList = ref(store.getters.concessionariaSelectedInfo)
    const bloquearBotao = ref(false);
    const isEdit = ref(true);
    const pedido = ref(true);
    const router = useRouter();
    const route = useRoute();
    const emitter = useEmitter();
    const { exportBlob } = usePDF();
    const distribuidor = store.getters.layoutConfig("distribuidor.distribuidor");
    const { showTimeAlert } = useAlert();
    const diasSegurancaEstoque = ref(1);
    const fornecedor = ref(1) // 1 = distribuidor  2 = outros 3 = originais 4 = todos
    const selectConcessionaria = ref(localStorage.getItem("filtroConcessionaria"));
    const dataUltimaAtt = ref()
    const necessaryUpdateEstoque = ref(false)
    const tooTipAttention = ref(true)
    interface paginationConfig {
      current_page: number,
      last_page: number,
      per_page: number,
      total: number,
      to: number,
      data: Array<[]>
    }
    const paginationConfig: paginationConfig = reactive({
      current_page: 1,
      last_page: 7,
      per_page: 50,
      total: 0,
      to: 0,
      data: []
    });
    const diasSegurancaEstoqueOption = [
      {
        text: "5 Dias",
        value: 5,
      },
      {
        text: "10 Dias",
        value: 10,
      },
      {
        text: "15 Dias",
        value: 15,
      },
      {
        text: "20 Dias",
        value: 20,
      },
      {
        text: "25 Dias",
        value: 25,
      },
      {
        text: "30 Dias",
        value: 30,
      },
      {
        text: "35 Dias",
        value: 35,
      },
      {
        text: "40 Dias",
        value: 40,
      },
      {
        text: "45 Dias",
        value: 45,
      },
      {
        text: "50 Dias",
        value: 50,
      },
      {
        text: "55 Dias",
        value: 55,
      },
      {
        text: "60 Dias",
        value: 60,
      },
    ];
    const seachServico = ref();
    const animate = ref(false)
    const todosProdutos: Ref<any> = ref([]);
    const servicoSelecionadoModal = ref()
    const indexServicoSelecionado = ref()
    const dadosExport: Ref<any[]> = ref([])
    const existeDms = ref(true)
    const disabledButtonAtualizarEstoque = ref(false)

    const valorTotalDosProtutos = computed(() => {
      let total = 0
      paginationConfig.data.forEach((produto: any) => {
        if (produto.valoresSimulacao) {
          total += Number(produto.valoresSimulacao.valorTotal)
        }
      })

      return total.toLocaleString('pt-br', { minimumFractionDigits: 2 })
    })


    const reloadSeByPush = () => {
      const byPushParam = route.params.byPush;
      if (byPushParam == undefined && props.title == undefined) {
        router.push('/sugestaoPedidos');
      }
    };

    const voltar = () => {
      router.back();
    };


    function totalPedido() {
      const valorTotalString = valorTotalDosProtutos.value;
      return valorTotalString;
    }

    async function salvar() {
      let dataPedidos: any = {}
      let numero = valorTotalDosProtutos.value.replace(/\./g, '').replace(' ', '.');
      const numeroFormatado = numero.replace(',', '.');

      if (props.title === "editar" && props.codSugestaoPedido) {
        const codSugestaoPedido = props.codSugestaoPedido;
        const hash_pedido = props.hash_pedido;

        dataPedidos = {
          "codSugestaoPedido": codSugestaoPedido,
          "hash_pedido": hash_pedido,
          "nomeConcessionaria": store.getters.concessionariaSelectedInfo.nomeFantasia,
          "diasSegurancaEstoque": diasSegurancaEstoque.value,
          "codAtria": store.getters.concessionariaSelectedInfo.codAtria,
          "codGrupo": store.getters.concessionariaSelectedInfo.codGrupo,
          "distribuidor": distribuidor,
          "nomeUser": store.getters.usuarioLogado.nome,
          "totalPedidos": numeroFormatado
        }
      }

      const codConcessionaria = window.localStorage.filtroConcessionaria;
      const codUsuario = store.getters.usuarioLogado.codUsuario;
      const total = Number(formatarTotal(totalPedido()));
      const diasDemanda = diasSegurancaEstoque.value;

      dataPedidos = {
        ...dataPedidos,
        "codConcessionaria": codConcessionaria,
        "codUsuario": codUsuario,
        "total": total,
        "diasDemanda": diasDemanda,
        "nomeConcessionaria": store.getters.concessionariaSelectedInfo.nomeFantasia,
        "diasSegurancaEstoque": diasSegurancaEstoque.value,
        "codAtria": store.getters.concessionariaSelectedInfo.codAtria,
        "codGrupo": store.getters.concessionariaSelectedInfo.codGrupo,
        "distribuidor": distribuidor,
        "nomeUser": store.getters.usuarioLogado.nome,
        "totalPedidos": numeroFormatado
      };
      const infoPedidos = informacoesTodosProdutos(dataPedidos);

      let itens: any;
      if (fornecedor.value != 4) {
        itens = infoPedidos.filter((produto) =>
          produto.codFornecedor == fornecedor.value
        );
      } else {
        itens = infoPedidos.slice();
      }

      if (dataPedidos.total == 0 && dataPedidos.totalPedidos) {
        Swal.fire({
          icon: "error",
          text: "Para salvar é necessário incluir no mínimo um produto com valor!"
        });
        return;
      }

      const dados = {
        ...dataPedidos,
        itens: itens
      }

      try {
        animate.value = true
        const link = await salvarSugestaoPedido(dados)
        window.open(link, 'OpenPDF');
        showTimeAlert("Sugestão de pedido salvo com sucesso!");
        goTo('/sugestaoPedidos')
      } catch (error) {
        showTimeAlert("Não foi possível salvar a sugestão de pedidos.");
        console.log(error);
      } finally {
        bloquearBotao.value = false;
      }
    }

    interface ProdutoInfo {
      codServico: number;
      lancamentos: { mes: string, quantidade: string }[];
      codFornecedor: number;
      valorUnitario: number | GLfloat;
      estoque: number;
      qtdSugerida: number;
      demandaQuinzenal: number;
      demandaSemanal: number;
      qtdCaixa: number;
      codSugestaoPedido?: any;
      codPeca?: string;
      ultimaCompra?: string;
      demandaMensal?: number;
      unidCaixa?: number;
      servico?: string;
      segurancaDeEstoque?: string;
      codSugestaoItem?: number;
      documento?: string;
      total: number;
      cont: number,
      dia: number,
      valoresSimulacao: any[],
      demandaSeguranca: number,
      quantidadeSugeridaIconAlert: boolean,
      quantidadeSugeridaInicial: number,
      quantiradeSugeridaAlterada: boolean,
      duracaoNovoEstoque: number,
      codwynns: any
    }

    function informacoesTodosProdutos(dataPedidos: any) {

      const informacoesProdutos: ProdutoInfo[] = [];

      todosProdutos.value.forEach((produto) => {
        if (!produto.total || produto.total == null || produto.total == undefined) {
          produto.total = 0;
        }

        const codFornecedor = parseInt(produto.fornecedor);
        const informacoesProduto: ProdutoInfo = {
          codSugestaoItem: produto.codSugestaoPedidoItem,
          segurancaDeEstoque: produto.segurancaDeEstoque,
          servico: produto.servico,
          codServico: produto.codwynns,
          codFornecedor: codFornecedor,
          valorUnitario: produto?.valorCompra.toLocaleString('pt-br', { minimumFractionDigits: 2 }),
          estoque: produto.estoque ?? 0,
          qtdSugerida: produto.quantidadeSugerida ?? 0,
          demandaQuinzenal: produto.demandaQuinzenal ?? 0,
          demandaSemanal: produto.demandaSemanal ?? 0,
          qtdCaixa: produto.unidadesCaixa ?? 0,
          lancamentos: [],
          codPeca: produto.intServico,
          ultimaCompra: produto.ultNfDtFaturada,
          documento: produto.ultNfFaturada,
          demandaMensal: produto.demandaMes,
          unidCaixa: produto.unidadesCaixa,
          total: produto.valoresSimulacao?.valorTotal ?? produto.total ?? 0,
          cont: produto.cont ?? 0,
          dia: produto.dia,
          valoresSimulacao: produto.valoresSimulacao ?? 0,
          demandaSeguranca: produto.demandaSeguranca ?? 0,
          quantidadeSugeridaIconAlert: produto.quantidadeSugeridaIconAlert ?? 0,
          quantidadeSugeridaInicial: produto.quantidadeSugeridaInicial ?? 0,
          quantiradeSugeridaAlterada: produto.quantiradeSugeridaAlterada ?? 0,
          duracaoNovoEstoque: produto.demandaMes ? Math.round((produto.valoresSimulacao.novoEstoque ? produto.valoresSimulacao.novoEstoque : produto.estoque) / (produto.demandaMes / 30)) : 0,
          codwynns: produto.codwynns
        };
        
        if (produto.lancamento_mes && produto.lancamento_qtd) {
          informacoesProduto.lancamentos = produto.lancamento_mes.map((mes, index) => ({
            mes: mes,
            quantidade: produto.lancamento_qtd[index]
          }));
        }

        informacoesProdutos.push(informacoesProduto);

      });
      return informacoesProdutos;
    }

    function goTo(url) {
      router.push(url);
    }

    function formatarTotal(total) {
      return total.replace(',', '');
    }

    function cortarString(string) {
      if (string.length > 55) {
        return string.substring(0, 55) + "...";
      } else {
        return string;
      }
    }

    function servicosExcel() {
      dadosExport.value?.map((date) => {
        if(date?.lancamento_mes == "" || date?.lancamento_mes == "" == undefined){
          date.lancamento_mes = null;
        }
        if(date?.lancamento_qtd == "" || date?.lancamento_qtd == "" == undefined){
          date.lancamento_qtd = null;
        }

        const mesTratado = date?.lancamento_mes?.map((item, index) => {
          const mes: any = tratarMes(item)
          return {
            [mes]: date.lancamento_qtd[index]
          }
        })
        const stringDemanda = `Semanal : ${date.demandaSemanal ? date.demandaSemanal : 0}\r\nQuinzenal : ${date.demandaQuinzenal ? date.demandaQuinzenal : 0}\r\nMês : ${date.demandaMes ? date.demandaMes : 0}`
        date.stringDemandaExcel = stringDemanda
        date.duracaoNovoEstoque = date.demandaMes ? Math.round((date.valoresSimulacao.novoEstoque ? date.valoresSimulacao.novoEstoque : date.estoque) / (date.demandaMes / 30)) : 0
        if (mesTratado) {
          date.historicoDeVenda = JSON.stringify(mesTratado).replace(/[:]/g, ' ---> ').replace(/[\[\]{"}]/g, '').replace(/[,]/g, '\r\n')
        }
      })
    }
    
    const notificationAttEstoque = async () => {
      const ultimaAtt = moment(dataUltimaAtt.value, "DD/MM/YYYY");
      const dataAtual = moment();

      const precisaAtualizar = ultimaAtt.isBefore(dataAtual, "day");
      if (precisaAtualizar) {
        tooTipAttention.value = true
      }
      necessaryUpdateEstoque.value = precisaAtualizar;
    }

    onMounted(async () => {

      reloadSeByPush();

      animate.value = true;
      if (props.codSugestaoPedido) {
        editarSugestaoPedido()
      } else {
        isEdit.value = true;
        getProdutos()
        dmsConcessionaria(selectConcessionaria.value)
      }
    });

    //renderiza em tela a informações da sugestao de pedido clicada
    async function editarSugestaoPedido() {
      isEdit.value = false;
      const codconcessionaria = props.codconcessionaria;
      const codSugestaoPedido = props.codSugestaoPedido;
      const nomeConcessionaria = props.nomeConcessionaria;
      disabledButtonAtualizarEstoque.value = true

      if (codconcessionaria && codSugestaoPedido) {

        todosProdutos.value = getSugestaoPedidosEstoqueItens(codconcessionaria, codSugestaoPedido).then((response) => {
          const itemComDiasDemanda: any = response.find(item => item.diasDemanda !== null && item.diasDemanda !== undefined);
          diasSegurancaEstoque.value = itemComDiasDemanda.diasDemanda;

          const repetir = true
          todosProdutos.value = calculoSugestaoDois(response, repetir);
          todosProdutos.value.sort((a, b) => {
            if (a.quantidadeSugerida > b.quantidadeSugerida) {
              return -1;
            } else if (a.quantidadeSugerida < b.quantidadeSugerida) {
              return 1;
            } else {
              return 0;
            }
          })

          todosProdutos.value.forEach((produto) => {
            produto.quantidadeSugeridaInicial = produto.quantidadeSugerida
            produto.quantiradeSugeridaAlterada = false
          })

          todosProdutos.value.forEach((produto) => {
            if (produto.lancamento_mes) {
              const resultado = produto.lancamento_mes
                .filter((mes, index) => produto.lancamento_mes[index] !== '' && produto.lancamento_mes[index] !== null)
                .map((mes, index) => `${tratarMes(mes)} : ${produto.lancamento_qtd[index]}`);
              produto.arrayMes = resultado
            } else {
              produto.arrayMes = []
            }
          })
          pagination(1);
          animate.value = false
        }).catch((err) => {
          console.log(err)
          showTimeAlert("Não foi possível completar a solicitação", "error");
          animate.value = false
        })
      }
    }

    function calculoSugestaoDois(response, repetir) {

      return response.map(produto => {
        return {
          ...produto,
          quantidadeSugerida: produto.qtdSugerida,
          nomeConcessionaria: props.nomeConcessionaria,
          cont: produto.cont,
          ultNfFaturada: Number(produto.ultNfFaturada),
          valoresSimulacao: {
            novoEstoque: ((produto?.unidadesCaixa ?? 0) * (produto?.qtdSugerida ?? 0)) + (produto?.estoque ?? 0),
            valorTotal: produto.total,
          },
          quantidadeSugeridaIconAlert: false,
        }
      })
    }

    function changeDiasSegurancaEstoque() {
      todosProdutos.value = calculoSugestao(todosProdutos.value);
      todosProdutos.value.forEach((produto) => {
        produto.quantidadeSugeridaInicial = produto.quantidadeSugerida
        produto.quantiradeSugeridaAlterada = false
      })
    }

    const tratarMes = (mesValue: string) => {
      if (mesValue == "01")
        return "Jan";
      if (mesValue == "02")
        return "Fev";
      if (mesValue == "03")
        return "Mar";
      if (mesValue == "04")
        return "Abr";
      if (mesValue == "05")
        return "Mai";
      if (mesValue == "06")
        return "Jun";
      if (mesValue == "07")
        return "Jul";
      if (mesValue == "08")
        return "Ago";
      if (mesValue == "09")
        return "Set";
      if (mesValue == "10")
        return "Out";
      if (mesValue == "11")
        return "Nov";
      if (mesValue == "12")
        return "Dez";
    };

    //calculo da demanda apartir do dia de segurança do estoque
    const calculoDemandaSeguranca = (demandaMes: number, segurancaEstoque: number): number => {
      return Math.round((demandaMes / 30) * segurancaEstoque);
    };

    // controle dos botões de adição e subtração
    const simularCompra = (produto, operadorMatematico: string) => {
      const produtoSelecionado = produto;
      const calculo = {
        adicao: () => {
          if (produto?.unidadesCaixa === 0) {
            produtoSelecionado.quantidadeSugerida = 0
            produto.valoresSimulacao = 0
            return
          }
          produtoSelecionado.quantidadeSugerida++;

          if (produtoSelecionado.quantidadeSugeridaInicial == produtoSelecionado.quantidadeSugerida) {
            produtoSelecionado.quantiradeSugeridaAlterada = false
          } else {
            produtoSelecionado.quantiradeSugeridaAlterada = true
          }

          const valoresSimulacao = EstoqueValorTotalDinamico(produtoSelecionado, "adicao");
          produtoSelecionado.valoresSimulacao = valoresSimulacao;
          return produtoSelecionado.quantidadeSugerida;
        },
        subtracao: () => {
          if (produtoSelecionado.quantidadeSugerida === 0) return 0;
          const valoresSimulacao = EstoqueValorTotalDinamico(produtoSelecionado, "subtracao");
          produtoSelecionado.valoresSimulacao = valoresSimulacao;

          produtoSelecionado.quantidadeSugerida--

          if (produtoSelecionado.quantidadeSugeridaInicial == produtoSelecionado.quantidadeSugerida) {
            produtoSelecionado.quantiradeSugeridaAlterada = false
          } else {
            produtoSelecionado.quantiradeSugeridaAlterada = true
          }

          return produtoSelecionado.quantidadeSugerida;
        }
      };
      return calculo[operadorMatematico]();
    };

    //calcular o novo estoque e o valor total. apartir da quantidade de compra sugerida
    function EstoqueValorTotalDinamico(produto, operadorMatematico) {
      const valoresSugerido = {
        novoEstoque: 0,
        valorTotal: 0,
      };
      if (operadorMatematico == "adicao") {
        const valorDaCaixa = produto.unidadesCaixa * produto.valorCompra;
        valoresSugerido.novoEstoque = (produto.quantidadeSugerida * produto.unidadesCaixa) + produto.estoque;
        valoresSugerido.valorTotal = (valorDaCaixa * produto.quantidadeSugerida);
        return valoresSugerido;
      }
      if (operadorMatematico == "subtracao") {
        const valorDaCaixa = produto.unidadesCaixa * produto.valorCompra;
        valoresSugerido.novoEstoque = produto.valoresSimulacao.novoEstoque - produto.unidadesCaixa;
        valoresSugerido.valorTotal = produto.valoresSimulacao.valorTotal - valorDaCaixa;
        return valoresSugerido;
      }
    }

    function calculoSugestao(ArrayDeProdutos, repetir?: boolean | null | undefined) {
      if (!ArrayDeProdutos) return []
      const novoArray = ArrayDeProdutos.map(produto => {
        let quantidadeSugerida = 0;
        let demandaSugerida;
        const unidadescaixa = produto.unidadesCaixa;
        const demandaSeguranca = calculoDemandaSeguranca(produto.demandaMes, diasSegurancaEstoque.value);
        const estoque = produto.estoque;
        let novoEstoque = estoque;
        if (unidadescaixa && demandaSeguranca) {
          if (unidadescaixa > 0 && demandaSeguranca > 0) {
            demandaSugerida = demandaSeguranca;
            if (estoque >= demandaSugerida) {
              produto.quantidadeSugerida = 0;
            }
            else {
              while (novoEstoque <= demandaSugerida) {
                novoEstoque += unidadescaixa;
                quantidadeSugerida++;
              }

              produto.quantidadeSugerida = quantidadeSugerida;
              produto.quantidadeSugeridaIconAlert = true;
              const valoresSimulacao = EstoqueValorTotalDinamico(produto, "adicao");
              produto.valoresSimulacao = valoresSimulacao;
              return produto;
            }
          }
        }
        produto.quantidadeSugeridaIconAlert = false;
        produto.quantidadeSugerida = 0;
        produto.valoresSimulacao = 0;
        return produto;
      });
      return novoArray;
    }
    const searchServico = () => {
      let delayTimer;
      clearTimeout(delayTimer);
      delayTimer = setTimeout(function () {
        pagination(1, true);
      }, 300);
    };

    function pagination(page: number = 1, filter: boolean = false) {
      let servicos = [];
      const filtroTodosProdutos = 4
      servicos = todosProdutos.value
      if (fornecedor.value == filtroTodosProdutos) {
        servicos = todosProdutos.value.sort((a, b) => {
          if (a.fornecedor > b.fornecedor) return 1;
          if (a.fornecedor < b.fornecedor) return -1;

          return 0
        })
      } else {
        servicos = todosProdutos.value?.filter((produto) =>
          produto.fornecedor == fornecedor.value);
      }
      if (filter && seachServico.value) {
        servicos = servicos.filter((concessionaria: any) => {
          if (!concessionaria.servico) return concessionaria.codwynns.toLowerCase().includes(seachServico.value.toLowerCase())
          if (!concessionaria.codwynns) return concessionaria.servico.toLowerCase().includes(seachServico.value.toLowerCase())

          return concessionaria.servico.toLowerCase().includes(seachServico.value.toLowerCase()) || concessionaria.codwynns.toLowerCase().includes(seachServico.value.toLowerCase())
        });
      }
      paginationConfig.total = servicos.length;
      paginationConfig.last_page = Math.ceil((servicos.length / paginationConfig.per_page)) ?? 0;
      paginationConfig.current_page = page;
      const start = paginationConfig.per_page * (page - 1);
      const end = paginationConfig.per_page * (page);
      if (paginationConfig.last_page == page) {
        paginationConfig.to = servicos.length;
      }
      else if (end > servicos.length) {
        paginationConfig.to = servicos.length;
      }
      else {
        paginationConfig.to = end;
      }

      dadosExport.value = servicos
      paginationConfig.data = servicos.slice(start, end);
      window.scroll({
        top: 0,
        behavior: 'smooth'
      })
    }
    // servico selecionado para o modal
    const servicoSelecionado = (servico, index) => {
      servicoSelecionadoModal.value = servico
      indexServicoSelecionado.value = index
    }
    //ao alterar o valor da unidade por caixa. refaz os calculos apenas do servico alterado
    const atualizarQuantidadeSugerida = (produtoAtualizado, index) => {
      let quantidadeSugerida = 0;
      let demandaSugerida;
      const unidadescaixa = produtoAtualizado.unidadesCaixa;
      const demandaSeguranca = calculoDemandaSeguranca(produtoAtualizado.demandaMes, diasSegurancaEstoque.value);
      const estoque = produtoAtualizado.estoque;
      let novoEstoque = estoque;

      if (unidadescaixa && demandaSeguranca) {
        if (unidadescaixa > 0 && demandaSeguranca > 0) {
          demandaSugerida = demandaSeguranca;
          if (estoque >= demandaSugerida) {

            Array(paginationConfig.data[index]).forEach((element: any) => {
              element.valoresSimulacao.quantidadeSugerida = 0
            });
          }
          else {

            while (novoEstoque <= demandaSugerida) {
              novoEstoque += unidadescaixa;
              quantidadeSugerida++;
            }
            produtoAtualizado.quantidadeSugerida = quantidadeSugerida;
            const valoresSimulacao = EstoqueValorTotalDinamico(produtoAtualizado, "adicao");
            Array(paginationConfig.data[index]).forEach((element: any) => element.valoresSimulacao = valoresSimulacao);
            return
          }
        }
      }

      Array(paginationConfig.data[index]).forEach((element: any) => element.valoresSimulacao = 0);

    }
    // atualizar unidade por caixa sem fazer requisição
    const novoUnidadeCaixa = async (novoValor) => {
      Array(paginationConfig.data[novoValor.indexServico]).forEach((element: any) => {
        element.unidadesCaixa = novoValor.unidadePorCaixa
        if (novoValor.unidadePorCaixa == 0) {
          element.quantidadeSugerida = 0
          element.valoresSimulacao = 0
        }
      });

      atualizarQuantidadeSugerida(paginationConfig.data[novoValor.indexServico], novoValor.indexServico)
      calculoSugestao(paginationConfig.data)
    }

    const novoCodPeca = (novoValor) => {
      Array(paginationConfig.data[novoValor.indexServico]).forEach((element: any) => element.intServico = novoValor.codPeca);
    }
    const novoCodFornecedor = (novoValor) => {
      Array(paginationConfig.data[novoValor.indexServico]).forEach((element: any) => element.codwynns = novoValor.codFornecedor);
    }

    async function getProdutos() {
      if (selectConcessionaria.value) {
        await getEstoqueConcessionaria(selectConcessionaria.value).then(async (response) => {
          diasSegurancaEstoque.value = response.segurancaDeEstoque; //dias de segurança da concessionaria

          dataUltimaAtt.value = response.ultimaDataEstoque
          await notificationAttEstoque();
          todosProdutos.value = calculoSugestao(response.dados);

          todosProdutos.value.sort((a, b) => {
            if (a.quantidadeSugerida > b.quantidadeSugerida) {
              return -1;
            } else if (a.quantidadeSugerida < b.quantidadeSugerida) {
              return 1;
            } else {
              return 0;
            }
          })

          todosProdutos.value.forEach((produto) => {
            produto.quantidadeSugeridaInicial = produto.quantidadeSugerida
            produto.quantiradeSugeridaAlterada = false
          })

          todosProdutos.value.forEach((produto) => {
            if (produto.lancamento_mes) {
              const resultado = produto.lancamento_mes.map((mes, index) => {
                return `${tratarMes(mes)} : ${produto.lancamento_qtd[index]}`
              });

              produto.arrayMes = resultado
            } else {
              produto.arrayMes = []
            }
          })

          pagination(1);
          animate.value = false
        }).catch((err) => {
          console.log(err)
          showTimeAlert("Não foi possível completar a solicitação", "error");
        })
      }
    }

    const dmsConcessionaria = async (codConcecionaria) => {
      tooTipAttention.value = false
      await getDmsConcessionaria(codConcecionaria)
        .then((data) => {

          if (data[0].conintegracao && data[0].buscaPeca) {

            existeDms.value = true
          } else {
            existeDms.value = false
          }

        })
        .catch(error => console.log(error))
    }

    const atualizarEstoqueButton = async () => {
      animate.value = true
      document.body.classList.add("page-loading")
      let produtoNaoAtualizado: any = []
      await atualizarEstoque(selectConcessionaria.value).then((response) => {
        animate.value = false
        document.body.classList.remove("page-loading")
        disabledButtonAtualizarEstoque.value = true
        produtoNaoAtualizado = response.produtosNaoAtualizado

        if (!response.erro) return showTimeAlert('Estoque atualizado com sucesso');
        showTimeAlert("Não foi possível completar a solicitação", "error");

      }).catch((err) => {
        animate.value = false
        document.body.classList.remove("page-loading")
        console.log(err)
      })

      await getProdutos()

      todosProdutos.value.map((produto) => {
        const refProduto: any = produtoNaoAtualizado.find(produtoAtualizado => produtoAtualizado.intServico == produto.intServico);

        if (refProduto) {
          produto.isUpdated = true
        }
      })

    }

    const distribuidorAtualizarEstoque = () => {
      const distribuidoresQueAtualizaEstoque = ['atria', 'localhost', 'homologacao', 'interozone']
      if (distribuidoresQueAtualizaEstoque.includes(distribuidor)) return true

      return false
    }

    emitter.on('geraXLSX', async () => {
      await servicosExcel()
      await gerarXLSX(dadosExport.value, diasSegurancaEstoque.value)
      exportarXLSX()
    })

    function servicosPdf() {
      dadosExport.value?.map((date) => {
        date.duracaoNovoEstoque = date.demandaMes ? Math.round((date.valoresSimulacao.novoEstoque ? date.valoresSimulacao.novoEstoque : date.estoque) / (date.demandaMes / 30)) : 0
      })
    }

    emitter.on('geraPDF', async () => {
      servicosPdf()
      emitter.emit("respostaPDF", {
        produtos: dadosExport.value,
        diasSegurancaEstoque: diasSegurancaEstoque.value,
      })
    })

    emitter.on('filtrar-codFornecedor', async (date) => {
      seachServico.value = ''
      fornecedor.value = await date.fornecedor
      pagination(1)
    })

    function avisoAttEstoque() {
      Swal.fire({
        title: "Atualização de estoque",
        text:
          "Este processo pode levar alguns minutos. Por favor, aguarde enquanto é realizado o processamento.",
        showCancelButton: true,
        confirmButtonText: "Atualizar estoque",
        cancelButtonText: "Cancelar",
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-flex background_btn_1_opct text-white",
          cancelButton: "btn btn-light me-3",
        },
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          atualizarEstoqueButton()
        }
      })
    }


    return {
      diasSegurancaEstoque,
      diasSegurancaEstoqueOption,
      todosProdutos,
      tratarMes,
      simularCompra,
      paginationConfig,
      pagination,
      seachServico,
      searchServico,
      servicoSelecionado,
      servicoSelecionadoModal,
      novoUnidadeCaixa,
      novoCodPeca,
      novoCodFornecedor,
      indexServicoSelecionado,
      atualizarEstoqueButton,
      animate,
      distribuidorAtualizarEstoque,
      existeDms,
      disabledButtonAtualizarEstoque,
      valorTotalDosProtutos,
      cortarString,
      dataUltimaAtt,
      tooTipAttention,
      necessaryUpdateEstoque,
      avisoAttEstoque,
      totalPedido,
      formatarTotal,
      informacoesTodosProdutos,
      editarSugestaoPedido,
      isEdit,
      getSugestaoPedidosEstoqueItens,
      salvar,
      changeDiasSegurancaEstoque,
      bloquearBotao,
      voltar
    };
  },

})
