import AuthService from "@/services/AuthService";
import store from "@/store";
import XLSX from "xlsx-js-style";

let blob;
export function gerarXLSX(servicos, diasSeguraca) {
  const userInfo = AuthService.getUsuarioAuth();
  const nomeUser = userInfo.nome;
  const nomeConcessionaria = store.getters.concessionariaSelectedInfo.nomeFantasia;
  const timeElapsed = Date.now();
  const today = new Date(timeElapsed);
  const dataExcelGerado: string = `${today.toLocaleDateString()} - ${today.toLocaleTimeString()} `;

  let servicosToXLSX = servicos?.map((item) => {
    const titleEstoqueDataAtual: string = `Estoque em ${today.toLocaleDateString()} ${today.toLocaleTimeString()} `;
    let ultimaCompra;

    if(item.ultNfFaturada == 0 || item.ultNfFaturada == null){
      ultimaCompra = "Sem registro"
    }else{
      ultimaCompra = 'NF ' + item.ultNfFaturada + ' de ' + item.ultNfDtFaturada
    }

    return {
      "Nome Serviço": item?.servico ?? "Não especificado",
      "Cod Fornecedor": item?.codwynns ?? "Não especificado",
      "Cod. Peça": item?.intServico ?? "Não especificado",
      "Qtd Und Caixa": item?.unidadesCaixa ?? "Não especificado",
      "Valor Unitário": item?.valorCompra ?? 0,
      "Historico de Vendas": item?.historicoDeVenda ?? "Sem histórico",
      "Demanda": item?.stringDemandaExcel ?? "Não especificado",
      [titleEstoqueDataAtual]: (item?.estoque ?? 0) + " Caixas",
      "Qtd Sugerida": (item?.quantidadeSugerida ?? 0) + " Caixas",
      "Estoque após Compra": (item?.valoresSimulacao?.novoEstoque ?? item?.estoque ?? 0) + " Unid",
      "Valor Total": item?.valoresSimulacao?.valorTotal ?? 0,
      "Duração Estoque": (item?.duracaoNovoEstoque ?? 0) + " Dias",
      "Última Compra": ultimaCompra 
    };
  });

  let wb: XLSX.WorkBook = XLSX.utils.book_new();
  wb.Props = {
    Title: "Serviços",
    Subject: "Serviços",
  };

  wb.SheetNames.push("Servicos");

  servicosToXLSX = servicosToXLSX && servicosToXLSX.length > 0  ? servicosToXLSX  : [
    {
      "Nome Serviço": "",
      "Cod Fornecedor": "",
      "Cod. Peça": "",
      "Qtd Und Caixa": "",
      "Valor Unitário": 0,
      "Historico de Vendas": "Sem histórico",
      "Demanda": "sem Demanda",
      "estoque": 0,
      "Qtd Sugerida": 0,
      "Estoque após Compra": 0,
      "Valor Total": 0,
      "Duração Estoque": "Sem dados",
    },
  ]
  const ws = XLSX.utils.json_to_sheet(servicosToXLSX)
  // mesclagem do valor total dos produtos
  const merge = [
    { s: { r: 0, c: 0 }, e: { r: 0, c: 10 } },
    { s: { r: 1, c: 0 }, e: { r: 1, c: 10 } },
    {
      s: { r: servicos.length + 3, c: 0 },
      e: { r: servicos.length + 3, c: 9 },
    },
  ];

  ws["!merges"] = merge;

  ws["!ref"] = ("A1:M") + (servicos.length + 4);

  let valor = 0;

  servicos.forEach((item) => {
    if (item.valoresSimulacao.valorTotal) {
      valor = valor + Number(item.valoresSimulacao.valorTotal);
    }
  });

  const wscols = [
    { wch: 20 }, //A
    { wch: 20 }, //B
    { wch: 17 }, //C
    { wch: 12 }, //D
    { wch: 13 }, //E
    { wch: 17 }, //F
    { wch: 14 }, //G
    { wch: 20 }, //H
    { wch: 12 }, //I
    { wch: 19 }, //j
    { wch: 12 }, //k
    { wch: 19 }, //L
    { wch: 19 }, //L
    { wch: 13 }, //M
  ];

  const wsrows = [
    { hpx: 50, level: 3 },
    { hpx: 30, level: 3 },
    { hpx: 18, level: 3 },
  ];

  servicos.forEach((_, index) => {
    wsrows.push({ hpx: 63, level: 3 });
  });
  ws["!cols"] = wscols;
  ws["!rows"] = wsrows;

  ws["A1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };
  ws["B1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };
  ws["C1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };
  ws["D1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };
  ws["E1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };
  ws["F1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };
  ws["G1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };
  ws["H1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };
  ws["I1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };
  ws["L1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };

  ws["J1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };
  ws["K1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };
  ws["M1"].s = {
    fill: {
      fgColor: { rgb: "b7b7b7" },
    },
  };

  // for para pegar em todas a linhas de uma coluna
  for (let i = 0; i < servicosToXLSX.length; i++) {
    const row = i + 2; // Para alinhar com o índice de linha esperado
    
    if (ws["A" + row]) {
      ws["A" + row].s = {
        alignment: { wrapText: true },
      };
    }
    if (ws["B" + row]) {
      ws["B" + row].s = {
        alignment: { wrapText: true },
      };
    }
    if (ws["C" + row]) {
      ws["C" + row].s = {
        alignment: { wrapText: true },
      };
    }
    if (ws["D" + row]) {
      ws["D" + row].s = {
        alignment: { horizontal: "left" },
      };
    }
    if (ws["E" + row]) {
      ws["E" + row].z = "R$0.00";
      ws["E" + row].s = {
        alignment: { horizontal: "left" },
      };
    }
    if (ws["F" + row]) {
      ws["F" + row].s = {
        alignment: { wrapText: true },
      };
    }
    if (ws["G" + row]) {
      ws["G" + row].s = {
        alignment: { wrapText: true },
      };
    }
    if (ws["H" + row]) {
      ws["H" + row].s = {
        alignment: { horizontal: "left" },
      };
    }
    if (ws["I" + row]) {
      ws["I" + row].s = {
        fill: {
          fgColor: {
            rgb: servicosToXLSX[i].quantidadeSugerida > 0 ? "efaeb3" : "bcdbd7",
          },
        },
        border: {
          top: { style: "thin", color: "dbdbdb" },
          bottom: { style: "thin", color: "dbdbdb" },
          left: { style: "thin", color: "dbdbdb" },
          right: { style: "thin", color: "dbdbdb" },
        },
        alignment: { horizontal: "left" },
      };
    }
    if (ws["J" + row]) {
      ws["J" + row].s = {
        alignment: { horizontal: "left" },
      };
    }
    if (ws["K" + row]) {
      ws["K" + row].z = "R$0.00";
      ws["K" + row].s = {
        alignment: { horizontal: "left" },
      };
    }
    if (ws["M" + row]) {
      ws["M" + row].s = {
        alignment: { wrapText: true },
      };
    }
  }//SAI DESSE FOR COM OS VALORES INDEX A BEM DIFERENTES DO PADRÃO

  // for para descer a tabela para a linha 3

  for (let index = 1; index < servicos.length + 2; index++) {
    // Corrigindo o deslocamento uniforme para todas as colunas A até M
    ws["A" + (index + 900)] = ws["A" + index];
    delete ws["A" + index];
  
    ws["B" + (index + 900)] = ws["B" + index];
    delete ws["B" + index];
  
    ws["C" + (index + 900)] = ws["C" + index];
    delete ws["C" + index];
  
    ws["D" + (index + 900)] = ws["D" + index];
    delete ws["D" + index];
  
    ws["E" + (index + 900)] = ws["E" + index];
    delete ws["E" + index];
  
    ws["F" + (index + 900)] = ws["F" + index];
    delete ws["F" + index];
  
    ws["G" + (index + 900)] = ws["G" + index];
    delete ws["G" + index];
  
    ws["H" + (index + 900)] = ws["H" + index];
    delete ws["H" + index];
  
    ws["I" + (index + 900)] = ws["I" + index];
    delete ws["I" + index];
  
    ws["J" + (index + 900)] = ws["J" + index];
    delete ws["J" + index];
  
    ws["K" + (index + 900)] = ws["K" + index];
    delete ws["K" + index];
  
    ws["L" + (index + 900)] = ws["L" + index];
    delete ws["L" + index];
  
    ws["M" + (index + 900)] = ws["M" + index];
    delete ws["M" + index];
  }
  //PASSA POR ESSE FOR E AS COLUNAS QUE NÃO APARECEM FICAM UNDEFINED

  for (let index = 1; index < servicos.length + 2; index++) {
    // let newKey = index + 3

    ws["A" + (index + 2)] = ws["A" + (index + 900)];
    delete ws["A" + (index + 900)];

    ws["B" + (index + 2)] = ws["B" + (index + 900)];
    delete ws["B" + (index + 900)];

    ws["C" + (index + 2)] = ws["C" + (index + 900)];
    delete ws["C" + (index + 900)];

    ws["D" + (index + 2)] = ws["D" + (index + 900)];
    delete ws["D" + (index + 900)];

    ws["E" + (index + 2)] = ws["E" + (index + 900)];
    delete ws["E" + (index + 900)];

    ws["F" + (index + 2)] = ws["F" + (index + 900)];
    delete ws["F" + (index + 900)];

    ws["G" + (index + 2)] = ws["G" + (index + 900)];
    delete ws["G" + (index + 900)];

    ws["H" + (index + 2)] = ws["H" + (index + 900)];
    delete ws["H" + (index + 900)];

    ws["I" + (index + 2)] = ws["I" + (index + 900)];
    delete ws["I" + (index + 900)];

    ws["J" + (index + 2)] = ws["J" + (index + 900)];
    delete ws["J" + (index + 900)];

    ws["K" + (index + 2)] = ws["K" + (index + 900)];
    delete ws["K" + (index + 900)];

    ws["L" + (index + 2)] = ws["L" + (index + 900)];
    delete ws["L" + (index + 900)];

    ws["M" + (index + 2)] = ws["M" + (index + 900)];
    delete ws["M" + (index + 900)];
  }


  //dados inserido no excel manualmente

  ws["A1"] = {
    v: `Sugestão para Pedidos - ${nomeConcessionaria}`,
    t: "s",
    s: {
      font: {
        sz: "24",
        bold: true
      },
      alignment: {
        vertical: "center",
      },
    },
  };

  ws["A2"] = {
    v:
      "Gerado por: " +
      nomeUser +
      " - Data sugestão: " +
      dataExcelGerado +
      " - Cálculo utilizando " +
      diasSeguraca +
      " dias como margem de segurança.",
    t: "s",
    s: {
      font: {
        sz: "11",
      },
      alignment: {
        vertical: "center",
      },
    },
  };

  // valor total

  ws["A" + (servicos.length + 4)] = {
    v: "Total",
    t: "s",
    s: {
      fill: {
        fgColor: {
          rgb: "FFD6D8DB",
        },
        bgColor: {
          indexed: "64",
        },
      },
    },
  };

  ws["K" + (servicos.length + 4)] = {
    v: valor,
    t: "n",
    s: {
      fill: {
        fgColor: {
          rgb: "FFD6D8DB",
        },
        bgColor: {
          indexed: "64",
        },
      },
      alignment: { horizontal: "left" },
    },
    z: "R$0.00",
  };

  wb.Sheets["Servicos"] = ws;

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
    var view = new Uint8Array(buf); //create uint8array as viewer
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff; //convert to octet
    return buf;
  }

  var wbout = XLSX.write(wb, { bookType: "xlsx", type: "binary" });

  blob = new Blob([s2ab(wbout)], {
    type: "text/plain;charset=UTF-8",
  });
}

export function exportarXLSX() {
    const nomeConcessionaria = store.getters.concessionariaSelectedInfo.nomeFantasia.replaceAll(' ', '_');
    const link = document.createElement("a");
    const timeElapsed = Date.now();
    const today = new Date(timeElapsed);
    const dataExcelGerado : string = today.toLocaleDateString()
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", "Sugestao_Pedidos_" + dataExcelGerado + '_'+ nomeConcessionaria.toUpperCase() + ".xlsx");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

export default {gerarXLSX,exportarXLSX}